import React, { useEffect, useState } from "react";
import { GET, POST, API_URL } from '../_config'


import { GiCreditsCurrency, GiMoneyStack } from 'react-icons/gi';
import { FiEdit } from 'react-icons/fi';
import { BsTagFill } from 'react-icons/bs';
import { clearInterval } from "timers";

const Modal = (props: Record<string, any>) => {

    //const headers = ['', 'Request Additional Pickup', 'Cancel Upcoming Pickup', 'Request Package Change', 'Request Additional Containers', 'Switch Container Types', 'Adjust Re-order Point', 'Adjust Current Inventory', 'Schedule Spend Consultation'];

    const [min, setMin] = useState<number>(1);
    const [max, setMax] = useState<number>(props.max);
    const [step, setStep] = useState<number>(1);
    const [icon, setIcon] = useState<boolean>(false);
    const [increase, setIncrease] = useState<boolean>(false);
    const [title, setTitle] = useState<any>('');
    const [value, setValue] = useState<number>(1);
    const [visible, setVisible] = useState<any>('');
    const [SETTINGS, setSETTINGS] = useState<any>({});


    const [isManual, setIsManual] = useState<boolean>(false);


    const [gcoin_error, set_gcoin_error] = useState<boolean>(false);
    const [gcoin, set_gcoin] = useState<boolean>(false);
    const [gcoin_error_msg, set_gcoin_error_msg] = useState<any>('');
    const [gcoin_redeem_msg, set_gcoin_redeem_msg] = useState<any>('');


    const [self_error, set_self_error] = useState<boolean>(false);
    const [self_error_msg, set_self_error_msg] = useState<any>('');


    const [OTP, setOTP] = useState<any>(null);
    const [VALID_OTP, set_VALID_OTP] = useState<any>(false);
    const [otp_error, set_otp_error] = useState<any>(false);




    const BUID = sessionStorage.getItem('resto-loyalty-buid');
    const USERID = sessionStorage.getItem('loyalty-user-id-' + BUID);

    const success = (res: any) => {
        if (res.data.Successful) {
            props.status('success');
            setTimeout(function () {
                setVisible('');
                props.status('');
                props.onHide();
            }, 2000);
        } else {
            //alert(res.data.Message);
            failed({});
        }

    }
    const failed = (res: any) => {
        props.status('error');
        setTimeout(function () {
            setVisible('');
            props.status('');
            props.onHide();
        }, 2000);
    }

    const OTP_VALIDATION = () => {
        set_otp_error(false)
        POST(API_URL.SEND_OTP, { Phone: sessionStorage.getItem('current-user-number' + BUID) }).then((resp: any) => {
            setOTP(resp.data.ID);
        }).catch((err: any) => { })
    }

    const validateOTP = () => {
        let otp_field: any = document.getElementById('otp');
        let otp = otp_field?.value;
        let status = otp.toString() === OTP.toString();
        if (status) {
            setOTP(null);
            finalConfirm();
        } else {
            set_otp_error(true)
        }
    }

    const confirm = () => {
        if (SETTINGS.OTPBaseRedemption) {
            switch (props.type) {
                case 'redeemCredit': {
                    let input: any = document.querySelector('input[name="value"]');
                    let value: any = input ? input.value == '' ? 0 : parseInt(input.value) : 0;

                    if (value !== '' && value !== 0) {
                        if (value > props.customer.Details.Credit) {
                            set_self_error(true);
                            set_self_error_msg('Insufficient credit');
                        } else {
                            OTP_VALIDATION();
                        }
                    } else {
                        set_self_error(true);
                        set_self_error_msg('Value must be greater than 0');
                    }
                    break;
                }
                case 'redeemGlobalCoin': {
                    let transactionAmount_field: any = document.querySelector('input[name="transactionAmount"]');
                    let gCoin_field: any = document.querySelector('input[name="globalRedeemCoin"]');
                    let note_field: any = document.querySelector('input[name="transactionNote"]');
                    let note = note_field?.value;
                    let transactionAmount = transactionAmount_field?.value;
                    let gCoin = gCoin_field?.value;

                    if (note === '') {
                        set_gcoin_error(true);
                        set_gcoin_error_msg('Transaction note required');
                    } else {
                        OTP_VALIDATION();
                    }

                    break;
                }

                case 'redeemBundles': {
                    let input: any = document.querySelector('input[name="value"]');
                    let value: any = input ? input.value : 0;
                    if (value > props.data.Number) {
                        set_self_error(true);
                        set_self_error_msg('Maximum ' + props.data.Number);
                    } else if (value <= 0) {
                        set_self_error(true);
                        set_self_error_msg('Minimum 1');
                    } else {
                        OTP_VALIDATION();
                    }

                    break;
                }
                case 'redeemRewards': {
                    let input: any = document.querySelector('input[name="value"]');
                    let value: any = input ? input.value : 0;
                    if (value > props.data.Number) {
                        set_self_error(true);
                        set_self_error_msg('Maximum ' + props.data.Number);
                    } else if (value <= 0) {
                        set_self_error(true);
                        set_self_error_msg('Minimum 1');
                    } else {
                        OTP_VALIDATION();
                    }
                    break;
                }
                case 'redeemOffer': {
                    let input: any = document.querySelector('input[name="value"]');
                    let value: any = input ? input.value : 0;
                    if (value > props.data.Number) {
                        set_self_error(true);
                        set_self_error_msg('Maximum ' + props.data.Number);
                    } else if (value <= 0) {
                        set_self_error(true);
                        set_self_error_msg('Minimum 1');
                    } else {
                        OTP_VALIDATION();
                    }
                    break;
                }
                default:
                    finalConfirm();

            }


        } else {
            finalConfirm();
        }

    }

    const finalConfirm = () => {
        switch (props.type) {
            case 'redeemCredit': {

                let input: any = document.querySelector('input[name="value"]');
                let value: any = input ? input.value == '' ? 0 : parseInt(input.value) : 0;

                if (value !== '' && value !== 0) {
                    if (value > props.customer.Details.Credit) {
                        set_self_error(true);
                        set_self_error_msg('Insufficient credit');
                    } else {
                        props.status('working');
                        setVisible('hide');
                        POST(API_URL.REDEEM_CREDIT, {
                            "fkbusinessunitid": BUID,
                            "fkuserid": USERID,
                            "fkLoyaltyUserID": props.customer.ID,
                            "transactions": value
                        }).then(success).catch(failed)
                    }
                } else {
                    set_self_error(true);
                    set_self_error_msg('Value must be greater than 0');
                }
                break;
            }

            case 'redeemGlobalCoin': {
                let transactionAmount_field: any = document.querySelector('input[name="transactionAmount"]');
                let gCoin_field: any = document.querySelector('input[name="globalRedeemCoin"]');
                let note_field: any = document.querySelector('input[name="transactionNote"]');
                let note = note_field?.value;
                let transactionAmount = transactionAmount_field?.value;
                let gCoin = gCoin_field?.value;

                if (note === '') {
                    set_gcoin_error(true);
                    set_gcoin_error_msg('Transaction note required');
                } else {
                    props.status('working');
                    setVisible('hide');
                    POST(API_URL.REDEEM_GCOIN, {
                        "BusinessUnitID": BUID,
                        "LoyaltyUserID": parseInt(props.customer.ID),
                        "CoinRedemptionQuantity": parseInt(gCoin),
                        "TransactionAmount": parseFloat(transactionAmount)
                    }).then(success).catch(failed)
                }

                break;
            }

            case 'redeemBundles': {
                let input: any = document.querySelector('input[name="value"]');
                let value: any = input ? input.value : 0;
                if (value > props.data.Number) {
                    set_self_error(true);
                    set_self_error_msg('Maximum ' + props.data.Number);
                } else if (value <= 0) {
                    set_self_error(true);
                    set_self_error_msg('Minimum 1');
                } else {
                    props.status('working');
                    setVisible('hide');
                    POST(API_URL.REDEEM_BUNDLES, {
                        "fkbusinessunitid": BUID,
                        "fkuserid": USERID,
                        "fkLoyaltyUserID": props.customer.ID,
                        "fkbundleid": props.data.BundleID,
                        "bundlequantity": value
                    }).then(success).catch(failed)
                }

                break;
            }
            case 'redeemRewards': {
                let input: any = document.querySelector('input[name="value"]');
                let value: any = input ? input.value : 0;
                if (value > props.data.Number) {
                    set_self_error(true);
                    set_self_error_msg('Maximum ' + props.data.Number);
                } else if (value <= 0) {
                    set_self_error(true);
                    set_self_error_msg('Minimum 1');
                } else {
                    props.status('working');
                    setVisible('hide');
                    POST(API_URL.REDEEM_REWARDS, {
                        "fkbusinessunitid": BUID,
                        "userid": USERID,
                        "loyaltyuserid": props.customer.ID,
                        "loyaltyrewards": props.data.RewardName,
                        "redeemquantity": value
                    }).then(success).catch(failed)
                }
                break;
            }
            case 'redeemOffer': {
                let input: any = document.querySelector('input[name="value"]');
                let value: any = input ? input.value : 0;
                if (value > props.data.Number) {
                    set_self_error(true);
                    set_self_error_msg('Maximum ' + props.data.Number);
                } else if (value <= 0) {
                    set_self_error(true);
                    set_self_error_msg('Minimum 1');
                } else {
                    props.status('working');
                    setVisible('hide');
                    POST(API_URL.REDEEM_REWARDS, {
                        "fkbusinessunitid": BUID,
                        "userid": USERID,
                        "loyaltyuserid": props.customer.ID,
                        "loyaltyrewards": props.data.OfferName,
                        "redeemquantity": value
                    }).then(success).catch(failed)
                }
                break;
            }
            case 'morestamp': {

                let transactionAmount_field: any = document.querySelector('input[name="transactionAmount"]');
                let stamps_field: any = document.querySelector('input[name="stamps"]');
                let gCoin_field: any = document.querySelector('input[name="globalCoin"]');
                let note_field: any = document.querySelector('input[name="transactionNote"]');

                let transactionAmount = transactionAmount_field?.value;
                let stamps = stamps_field?.value;
                let gCoin = gCoin_field?.value;
                let note = note_field?.value;
                if (SETTINGS.EnableTransactionNote && note == '') {
                    alert('Transaction Note Required.');
                    return false;
                }
                if (transactionAmount !== '' && transactionAmount > 0) {
                    props.status('working');
                    setVisible('hide');
                    POST(API_URL.ADD_STAMP, {
                        fkbusinessunitid: BUID,
                        userid: USERID,
                        loyaltyuserid: props.customer.ID,
                        stamps: stamps,
                        Notes: note,
                        TransactionAmount: transactionAmount,
                        IsManual: isManual,
                        GlobalCoins: gCoin
                    }).then(success).catch(failed)
                } else {
                    alert('Transaction Amount should not be blank or 0')
                }
                break;
            }
            case 'addBundles': {
                let input: any = document.querySelector('input[name="value"]');
                let value: any = input ? parseInt(input.value) : 0;
                if (value <= 0) {
                    set_self_error(true);
                    set_self_error_msg('Minimum 1');
                } else {
                    props.status('working');
                    setVisible('hide');
                    let settings = {
                        "fkbusinessunitid": BUID,
                        "fkuserid": USERID,
                        "fkLoyaltyUserID": props.customer.ID,
                        "fkbundleid": props.data.BundleID,
                        "bundlequantity": value
                    }

                    POST(API_URL.ADD_BUNDLE, settings).then(success).catch(failed)
                }
            }
        }
    }

    const getStoreSettings = () => {
        GET(API_URL.STORE_SETTINGS, { BusinessUnitID: BUID },).then((resp: any) => {
            setSETTINGS(resp.data.LoyaltySettingsGetList[0])
        }).catch((err: any) => {
            console.error('settings not available.')
        })
    }

    const calculate = () => {
        setIsManual(false);


        let transactionAmount_field: any = document.querySelector('input[name="transactionAmount"]');
        let stamps_field: any = document.querySelector('input[name="stamps"]');
        let gCoin_field: any = document.querySelector('input[name="globalCoin"]');
        let transactionAmount = transactionAmount_field?.value;

        GET(API_URL.CALCULATE_STAMP_COIN, {
            LoyaltyUserID: props.customer.ID,
            BusinessUnitID: BUID,
            TransactionAmount: transactionAmount,
            r: (new Date()).getTime()
        }).then((resp: any) => {
            if (stamps_field) {
                stamps_field.value = resp.data.StampCount
            }
            if (gCoin_field && SETTINGS.EnableCoinGiveaway) {
                gCoin_field.value = resp.data.GlobalCoinCount
            }
        })
    }
    const getCoinAmount = () => {

        let transactionAmount_field: any = document.querySelector('input[name="transactionAmount"]');
        let gCoin_field: any = document.querySelector('input[name="globalRedeemCoin"]');
        let transactionAmount = transactionAmount_field?.value;
        let gCoin = gCoin_field?.value;

        if (transactionAmount !== '' && gCoin !== '') {
            GET(API_URL.CALCULATE_GCOIN_AMOUNT, {
                LoyaltyUserID: props.customer.ID,
                BusinessUnitID: BUID,
                CoinRedemptionQuantity: gCoin,
                TransactionAmount: transactionAmount,
                r: (new Date()).getTime()
            }).then((resp: any) => {
                if (resp.data.Successful) {
                    set_gcoin_error(false);
                    set_gcoin_error_msg('');
                    set_gcoin_redeem_msg('Redemption Amount is ' + resp.data.RedemptionAmount);
                    set_gcoin(true)
                } else {
                    set_gcoin_error(true);
                    set_gcoin_error_msg(resp.data.Message);
                    set_gcoin(false)
                }
            })
        } else {
            set_gcoin_error(false);
            set_gcoin_error_msg('');
            set_gcoin(false)
        }


    }



    const addEvents = () => {
        switch (props.type) {
            case 'redeemCredit': {

                break;
            }
            case 'redeemGlobalCoin': {
                set_gcoin_error(false);
                set_gcoin(false);
                set_gcoin_error_msg('');
                set_gcoin_redeem_msg('');

                break;
            }
            case 'redeemBundles': {

                break;
            }
            case 'redeemRewards': {

                break;
            }
            case 'morestamp': {

                break;
            }
            case 'addBundles': {

                break;
            }
        }
    }
    const removeEvents = () => {
        switch (props.type) {
            case 'redeemCredit': {

                break;
            }
            case 'redeemGlobalCoin': {

                break;
            }
            case 'redeemBundles': {

                break;
            }
            case 'redeemRewards': {

                break;
            }
            case 'morestamp': {

                break;
            }
            case 'addBundles': {

                break;
            }
        }
    }


    useEffect(() => {
        switch (props.type) {
            case 'redeemCredit': {
                setTitle('How much credit would you like to redeem?');
                setMin(0.01);
                setStep(0.01);
                setValue(0);
                break;
            }
            case 'redeemGlobalCoin': {
                setTitle('How much Global Coin would you like to redeem?');
                setMin(1);
                setStep(1);
                setValue(0);
                break;
            }
            case 'redeemBundles':
            case 'redeemOffer':
            case 'redeemRewards': {
                setTitle('How many would you like to redeem?');
                break;
            }
            case 'morestamp': {
                setTitle('Give Out');
                break;
            }
            case 'addBundles': {
                //setIncrease(true)
                setTitle('How many bundles would you like to give out?');
                break;
            }
        }
    }, [props.type])




    useEffect(() => {
        set_self_error(false);
        set_self_error_msg('');
        if (props.show) {
            getStoreSettings();
            addEvents();
        } else {
            removeEvents();
        }
    }, [props.show]);
    useEffect(() => {
        console.log('Effect VALID_OTP=>', VALID_OTP)
    }, [VALID_OTP]);



    return (
        <React.Fragment>
            {props.show ?

                (props.type === 'morestamp') ?
                    <div className={"add-modal " + visible}>
                        <div className="modal page-center">
                            <h3 className="title">{title}</h3>

                            {SETTINGS.EnableTransactionNote ?
                                <div className="card field">
                                    <label>Note</label>
                                    <FiEdit className="field-icon" style={{ color: '#113b6b' }} />
                                    <input type="text" min={0} name="transactionNote" placeholder="Note" />
                                </div>
                                : null}


                            <div className="card field">
                                <label>Transaction Amount</label>
                                <GiMoneyStack className="field-icon" />
                                <input type="number" min={0} name="transactionAmount" placeholder="Transaction Amount" onChange={calculate} />
                            </div>

                            <div className="card field">
                                <label>Stamps</label>
                                <BsTagFill className="field-icon" />
                                <input type="number" min={0} name="stamps" placeholder="Stamps" onChange={() => { setIsManual(true) }} />
                            </div>

                            {SETTINGS.EnableCoinGiveaway ?
                                <div className="card field">
                                    <label>Global Coin</label>
                                    <GiCreditsCurrency className="field-icon" style={{ color: '#d3ae08' }} />
                                    <input type="number" min={0} name="globalCoin" disabled={true} placeholder="Global Coin" />
                                </div>
                                : null}







                            {/* <div className="card block">
                                {icon ? <div className="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" width="40px" height="40px" x="0px" y="0px" viewBox="0 0 512 512"><path xmlns="http://www.w3.org/2000/svg" fill="#e4e4e4" d="M437.019,74.98C388.668,26.629,324.381,0,256,0C187.62,0,123.333,26.629,74.981,74.98C26.629,123.332,0,187.619,0,256    s26.628,132.668,74.981,181.02C123.333,485.371,187.62,512,256,512c68.381,0,132.668-26.629,181.02-74.98    C485.372,388.668,512,324.381,512,256S485.372,123.332,437.019,74.98z M272.068,367.4H271v33.201c0,8.284-6.715,15-15,15    c-8.283,0-15-6.716-15-15V367.4h-33.199c-8.283,0-15-6.716-15-15s6.717-15,15-15h64.268c18.306,0,33.199-14.894,33.199-33.2    c0-18.306-14.894-33.2-33.2-33.2h-32.135c-34.848,0-63.199-28.351-63.199-63.199c0-34.849,28.352-63.2,63.199-63.2H241v-33.2    c0-8.284,6.717-15,15-15c8.285,0,15,6.716,15,15v33.2h33.201c8.283,0,15,6.716,15,15s-6.717,15-15,15h-64.268    c-18.307,0-33.199,14.893-33.199,33.2c0,18.306,14.893,33.199,33.199,33.199h32.135c34.848,0,63.199,28.352,63.199,63.2    S306.916,367.4,272.068,367.4z"></path></svg>
                                </div> : null}
                                {increase ? <div className="decrease">-</div> : null}

                                <input type="number" name="value" defaultValue={value} max={max} min={min} step={step} />
                                {increase ? <div className="increase">+</div> : null}

                            </div> */}
                            <div className="btns">
                                <button className="cancel" onClick={() => { props.onHide() }}>Cancel</button>
                                <button className="confirm" onClick={confirm}>Confirm</button>
                            </div>
                        </div>
                    </div>
                    :
                    props.type === 'redeemGlobalCoin' ?
                        <div className={"add-modal " + visible}>
                            <div className="modal page-center" data-type="redeemBundles">
                                <h3 className="title">{title}</h3>


                                <div className="card field">
                                    <label>Note</label>
                                    <FiEdit className="field-icon" style={{ color: '#113b6b' }} />
                                    <input type="text" min={0} name="transactionNote" placeholder="Transaction Note" onKeyUp={() => { set_gcoin_error(false) }} />
                                </div>



                                <div className="card field">
                                    <label>Transaction Amount</label>
                                    <GiMoneyStack className="field-icon" />
                                    <input type="number" min={0} name="transactionAmount" placeholder="Transaction Amount" onChange={getCoinAmount} />
                                </div>


                                <div className="card field">
                                    <label>Transaction Amount</label>
                                    <GiCreditsCurrency className="field-icon" style={{ color: '#d3ae08' }} />
                                    {increase ? <div className="decrease">-</div> : null}

                                    <input type="number" name="globalRedeemCoin" max={max} min={min} step={step} placeholder="Global Coins" onChange={getCoinAmount} />
                                    {increase ? <div className="increase">+</div> : null}
                                </div>
                                {gcoin_error ? <div className="error">{gcoin_error_msg}</div> : null}
                                {gcoin ? <div className="success">{gcoin_redeem_msg}</div> : null}


                                <div className="btns">
                                    <button className="cancel" onClick={() => { props.onHide() }}>Cancel</button>
                                    <button className="confirm" onClick={confirm} disabled={!gcoin}>Confirm</button>
                                </div>
                            </div>
                        </div>
                        :
                        <div className={"add-modal " + visible}>
                            <div className="modal page-center" data-type="redeemBundles">
                                <h3 className="title">{title}</h3>
                                <div className="card field">
                                    {icon ? <div className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" width="40px" height="40px" x="0px" y="0px" viewBox="0 0 512 512"><path xmlns="http://www.w3.org/2000/svg" fill="#e4e4e4" d="M437.019,74.98C388.668,26.629,324.381,0,256,0C187.62,0,123.333,26.629,74.981,74.98C26.629,123.332,0,187.619,0,256    s26.628,132.668,74.981,181.02C123.333,485.371,187.62,512,256,512c68.381,0,132.668-26.629,181.02-74.98    C485.372,388.668,512,324.381,512,256S485.372,123.332,437.019,74.98z M272.068,367.4H271v33.201c0,8.284-6.715,15-15,15    c-8.283,0-15-6.716-15-15V367.4h-33.199c-8.283,0-15-6.716-15-15s6.717-15,15-15h64.268c18.306,0,33.199-14.894,33.199-33.2    c0-18.306-14.894-33.2-33.2-33.2h-32.135c-34.848,0-63.199-28.351-63.199-63.199c0-34.849,28.352-63.2,63.199-63.2H241v-33.2    c0-8.284,6.717-15,15-15c8.285,0,15,6.716,15,15v33.2h33.201c8.283,0,15,6.716,15,15s-6.717,15-15,15h-64.268    c-18.307,0-33.199,14.893-33.199,33.2c0,18.306,14.893,33.199,33.199,33.199h32.135c34.848,0,63.199,28.352,63.199,63.2    S306.916,367.4,272.068,367.4z"></path></svg>
                                    </div> : null}
                                    {increase ? <div className="decrease">-</div> : null}

                                    <input type="number" name="value" defaultValue={value} max={max} min={min} step={step} onChange={() => { set_self_error(false) }} />
                                    {increase ? <div className="increase">+</div> : null}

                                </div>

                                {self_error ? <div className="error">{self_error_msg}</div> : null}


                                <div className="btns">
                                    <button className="cancel" onClick={() => { props.onHide() }}>Cancel</button>
                                    <button className="confirm" onClick={confirm}>Confirm</button>
                                </div>
                            </div>
                        </div>
                : null}

            {OTP ? <div className="otp modal">
                <div className="page-center">
                    <h2>Verify Customer OTP</h2>
                    <div className="card field">
                        <input type="text" id="otp" maxLength={4} name="transactionAmount" placeholder="OTP" onKeyUp={() => { set_otp_error(false) }} />
                    </div>
                    {otp_error ? <div className="error">Invalid OTP</div> : null}

                    <div className="btns"><button className="cancel" onClick={() => { setOTP(null) }}>Cancel</button><button className="confirm" onClick={validateOTP}>Validate</button></div>

                </div>
            </div> : null}
        </React.Fragment>
    )
};

export default Modal