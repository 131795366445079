import React, { useEffect, useState } from 'react';
import Header from '../../component/header';
import Footer from '../../component/footer';
import UserFinder from '../../component/userFinder';
import Modal from '../../component/modal';
import { GET, POST, API_URL } from '../../_config'
export default function Credits() {
    const [newUser, setNewUser] = useState<boolean>(false);
    const [number, setNumber] = useState<any>(null);
    const [user, setUser] = useState<any>(null);
    const [bundles, setBundles] = useState<any>(null);
    const [bundle, setBundle] = useState<any>(null);
    const [status, setStatus] = useState<any>('');
    const [modal, setModal] = useState<boolean>(false);
    const [STORE_DATA, set_STORE_DATA] = useState<any>({});
    const modalClose = () => {
        setBundle(null);
        setModal(false);
    }

    const BUID = sessionStorage.getItem('resto-loyalty-buid');
    const USERID = sessionStorage.getItem('loyalty-user-id-' + BUID);

    const onUser = (user: any, number: any) => {
        console.log("User finder =>", user)
        if (user) {
            setNewUser(user.NewUser);
            setNumber(number);
            setUser(user);
            if (!user.NewUser) {
                //getBundles();
            }
        } else {
            setNewUser(false);
            setNumber(null);
            setUser(null);
        }
    }

    const addUser = () => {
        setNewUser(false);
        POST(API_URL.ADD_USER, {
            loyaltyuserid: 0,//_this.user.id,
            fkbusinessunitid: BUID,
            mobile: number,
            name: null,
            email: null,
            dob: null
        }).then((resp: any) => {
            setUser(resp.data);
            //getBundles();
        }).catch((err: any) => {

        })
    }

    const buttonLabel = () => {

        let input: any = document.querySelector('[name="creditInput"]');

        let value = input?.value;
        switch (status) {
            case 'working': return 'Adding...';
            case 'success': return STORE_DATA?.CurrencyShortCode + parseFloat(value).toFixed(2) + ' Credit Added.';
            case 'error': return 'Something Wrong! Please try after sometimes.';
            default: return 'Add Credit';
        }
    }


    const addCredit = () => {
        let input: any = document.querySelector('[name="creditInput"]');
        if (input) {
            let value = input.value;
            setStatus('working');

            if (value === "" || value === 0) {
                input.classList.add('error');
            } else {
                POST(API_URL.ADD_CREDIT, {
                    "fkbusinessunitid": BUID,
                    "fkuserid": USERID,
                    "fkLoyaltyUserID": user.ID,
                    "transactions": value
                }).then((resp: any) => {
                    setStatus('success');
                    setTimeout(() => { setStatus('') }, 2000)
                }).catch((err: any) => {
                    setStatus('error');
                    setTimeout(() => { setStatus('') }, 2000)
                })
            }
        }


    }

    useEffect(() => {
        let storeData: any = sessionStorage.getItem('resto-store-' + BUID);
        if (storeData) {
            //storeData.StoreBackground = null;
            set_STORE_DATA(JSON.parse(storeData))
        }
    }, [])

    return (
        <React.Fragment>
            <div className="fixed-background-bg loyalty-bg-crew"></div>
            <Header />
            <section className="view-area">
                <h2>Credit</h2>
                <UserFinder onUser={onUser} />
                {newUser ?
                    <div className="newuser card" onClick={addUser}>
                        <div className="number">+919830505538</div>
                        <div className="label">New User</div>
                    </div>
                    :
                    user ?
                        <div className="section">
                            <h2 className="title">How much would you like to add?</h2>
                            <div className="credit-input">
                                <div className="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" width="40px" height="40px" x="0px" y="0px" viewBox="0 0 512 512" ><path xmlns="http://www.w3.org/2000/svg" fill="#e4e4e4" d="M437.019,74.98C388.668,26.629,324.381,0,256,0C187.62,0,123.333,26.629,74.981,74.98C26.629,123.332,0,187.619,0,256    s26.628,132.668,74.981,181.02C123.333,485.371,187.62,512,256,512c68.381,0,132.668-26.629,181.02-74.98    C485.372,388.668,512,324.381,512,256S485.372,123.332,437.019,74.98z M272.068,367.4H271v33.201c0,8.284-6.715,15-15,15    c-8.283,0-15-6.716-15-15V367.4h-33.199c-8.283,0-15-6.716-15-15s6.717-15,15-15h64.268c18.306,0,33.199-14.894,33.199-33.2    c0-18.306-14.894-33.2-33.2-33.2h-32.135c-34.848,0-63.199-28.351-63.199-63.199c0-34.849,28.352-63.2,63.199-63.2H241v-33.2    c0-8.284,6.717-15,15-15c8.285,0,15,6.716,15,15v33.2h33.201c8.283,0,15,6.716,15,15s-6.717,15-15,15h-64.268    c-18.307,0-33.199,14.893-33.199,33.2c0,18.306,14.893,33.199,33.199,33.199h32.135c34.848,0,63.199,28.352,63.199,63.2    S306.916,367.4,272.068,367.4z"></path></svg>
                                </div>
                                <input type="number" name="creditInput" step="0.01" min="0.01" placeholder="0.00" />
                            </div>

                            <div className={"card block-button " + status} onClick={addCredit}>{buttonLabel()}</div>
                        </div> : null
                }




            </section>
            <Footer />
        </React.Fragment>
    );
}